import React from "react"
import SEO from "../common/seo/seo"
import ServicesPdfTemplate from "../modules/servicesPdf/ServicesPdf.template"

const ServicesPdf = () => {
  return (
    <div>
      <SEO title="Services PDF" />
      <ServicesPdfTemplate />
    </div>
  )
}

export default ServicesPdf
