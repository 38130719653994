import React, { Component } from "react"
import ServicesPdfStyle from "./ServicesPdfStyle.module.styl"
import ServicePDF from "../../assets/pdf/Services_PDF.pdf"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class ServicesPdfTemplate extends Component {
  componentDidMount = () => {
    window.location.href = `${ServicePDF}`
  }
  render() {
    return (
      <div className={ServicesPdfStyle.pdf_wrapper}>
        {/*{" "}*/}
        {/*<a*/}
        {/*  target="_blank"*/}
        {/*  className={ServicesPdfStyle.button}*/}
        {/*  href={ServicePDF}*/}
        {/*>*/}
        {/*  Download PDF <FontAwesomeIcon icon={faAngleRight} />*/}
        {/*</a>*/}
      </div>
    )
  }
}

export default ServicesPdfTemplate
